// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-detail-page-js": () => import("./../../../src/detail/page.js" /* webpackChunkName: "component---src-detail-page-js" */),
  "component---src-detail-post-js": () => import("./../../../src/detail/post.js" /* webpackChunkName: "component---src-detail-post-js" */),
  "component---src-detail-pro-people-js": () => import("./../../../src/detail/pro_people.js" /* webpackChunkName: "component---src-detail-pro-people-js" */),
  "component---src-detail-pro-service-js": () => import("./../../../src/detail/pro_service.js" /* webpackChunkName: "component---src-detail-pro-service-js" */),
  "component---src-list-page-js": () => import("./../../../src/list/page.js" /* webpackChunkName: "component---src-list-page-js" */),
  "component---src-list-post-js": () => import("./../../../src/list/post.js" /* webpackChunkName: "component---src-list-post-js" */),
  "component---src-list-pro-people-js": () => import("./../../../src/list/pro_people.js" /* webpackChunkName: "component---src-list-pro-people-js" */),
  "component---src-list-resources-articles-js": () => import("./../../../src/list/resources/articles.js" /* webpackChunkName: "component---src-list-resources-articles-js" */),
  "component---src-list-resources-category-js": () => import("./../../../src/list/resources/category.js" /* webpackChunkName: "component---src-list-resources-category-js" */),
  "component---src-list-resources-events-js": () => import("./../../../src/list/resources/events.js" /* webpackChunkName: "component---src-list-resources-events-js" */),
  "component---src-list-resources-past-events-js": () => import("./../../../src/list/resources/past-events.js" /* webpackChunkName: "component---src-list-resources-past-events-js" */),
  "component---src-list-resources-podcasts-js": () => import("./../../../src/list/resources/podcasts.js" /* webpackChunkName: "component---src-list-resources-podcasts-js" */),
  "component---src-list-resources-videos-js": () => import("./../../../src/list/resources/videos.js" /* webpackChunkName: "component---src-list-resources-videos-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-resources-search-js": () => import("./../../../src/pages/resources/search.js" /* webpackChunkName: "component---src-pages-resources-search-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */)
}

